(function ($) {
  Drupal.behaviors.productAddToGift = {
    attach: function (context) {
      if (!Drupal.settings.common.show_product_gifting) {
        return null;
      }

      var that = this;

      that.multiAdd = false;
      that.isOpen = false;
      that.doSort = true;
      that.giftSets = [];

      that.prodId = '';
      that.skuBaseId = '';
      that.giftSkuId = '';
      that.itemQty = 1;

      that.setShadeChangeBtnUpdate();
      that.setQuantityChangeUpdate();

      // Check for product.init event on quickshop.
      $(document).on('product.init', function (event) {
        that.setMenuBtn(event);
      });

      that.setMenuBtn();
    },

    // The add-to-giftbox button stores the SKU_BASE_ID as a data attribute called
    // data-sku-base-id, and uses it later when completing the gifting popup form.
    // This function ensures that the button's data-sku-base-id is up to date
    // as the user changes shades.
    setShadeChangeBtnUpdate: function () {
      var that = this;

      that.nodes.$addGiftNode = $('.js-add-to-giftbox');

      $(document).on('product.skuSelect', function (event, data) {
        // Expecting data to be skuBaseId from product_sku_select_v1.js
        if (typeof data !== 'undefined') {
          data = typeof data === 'number' ? data.toString() : data;
          that.nodes.$addGiftNode.attr('data-sku-base-id', data);
          // Update the member variable too. User could change shade while
          // add-to-gift popup is open.
          that.skuBaseId = data;
        }
      });
    },

    // Same as setShadeChangeBtnUpdate except for quantity change now.
    setQuantityChangeUpdate: function () {
      var that = this;

      // Keep itemQty up-to-date as the quantity is changed on the page.
      $(document).on('product.qtySelect', function (event, data) {
        if (typeof data !== 'undefined') {
          data = typeof data === 'number' ? data : parseInt(data);
          that.itemQty = data;
        }
      });
    },

    setMenuBtn: function (event) {
      var that = this;
      var initClass = 'set';
      var $container = event && event.target ? event.target : $('.js-product-full');

      that.nodes.$addGiftNode = $('.js-add-to-giftbox', $container);

      // Prevent button set-up happening more than once.
      if (that.nodes.$addGiftNode.hasClass(initClass)) {
        return null;
      } else {
        that.nodes.$addGiftNode.addClass(initClass);
      }

      // Click "Add to Gift" button to set context.
      that.nodes.$addGiftNode.bind('click', function (evt) {
        evt.preventDefault();

        var $btn = $(this);

        that.nodes.init($btn);

        that.inQuickShop = $btn.parents('.js-quickshop-container').length;
        that.prodId = $btn.data('product-id');
        that.skuBaseId = $btn.data('sku-base-id');
        that.giftSkuId = !that.multiAdd ? that.skuBaseId : undefined;

        if ($btn.hasClass('active')) {
          $btn.removeClass('active');
          that.hideGiftMenu();

          return null;
        }

        // If in quickshop, add additional class for styling.
        if (that.inQuickShop) {
          that.nodes.$giftForm.addClass('in-quickshop');
          that.nodes.$quickshop.addClass('gifting-open'); // Helps fix overflow issue with SPP Cross sells
        }

        $btn.addClass('active');

        that.clearErrorMsg();
        that.setEvents();
        that.showProgress();
        that.clearGiftNameInput();
        that.showGiftMenu();
        that.setCloseBtn();
        that.getCollections();
        that.setScrolling();
      });
    },

    /**
     * Method to set all DOM events for the drop down.
     * Any new events, like a close button, should be added here.
     */
    setEvents: function () {
      var that = this;
      // Allow for Enter Keypress to submit new gift
      var giftName = document.getElementById('js-new-item-text');

      if (giftName) {
        giftName.onkeydown = function (e) {
          var evt = e ? e : event;
          var keyCode = evt.keyCode;

          if (keyCode == 13) {
            if (that.nodes.$addNewItem.hasClass('hidden')) {
              return;
            } else {
              that.nodes.$addGiftBtn.trigger('click');
            }
            Event.stop(evt);
          }
        };
      }

      if (!this.multiAdd) {
        $(document).bind('select:sku', function (evt) {
          that.giftSkuId = evt.memo.SKU_BASE_ID;
        });
      }

      that.nodes.$addGiftBtn.unbind('click');
      that.nodes.$addGiftBtn.bind('click', function (evt) {
        that.handler(that);
      }); // end dropdownAddBtn

      that.nodes.$newGiftText.bind('click', function () {
        var $options = that.nodes.$dropdown.children();

        $options.removeClass('active');
        that.nodes.$chosenGiftsetID.val('');
        that.nodes.$chosenGiftsetName.val('');
      });
    },

    /**
     * Method to set the close button when overlay is opened.
     */
    setCloseBtn: function () {
      var that = this;

      that.nodes.$closeBtn.bind('click', function () {
        that.hideGiftMenu();
      });
    },

    /**
     * Method to set the drop down options when multiple gifts are available.
     * Iterates through the user's gift collections and creates a list item per gift.
     * Populates this.nodes.$dropdown when completed.
     * @param {Array} array **REQUIRED** Array of gift collections that comes from the user.giftSets rpc call.
     */
    resetDropdown: function (array) {
      if (array.length <= 0) {
        return null;
      }

      // Sort items alphabetically if this.doSort is set to true.
      var items = this.doSort ? _.sortBy(array, 'COLLECTION_NAME') : array;

      this.nodes.$dropdown.html('');
      if ($.isArray(items)) {
        for (var i in items) {
          var set = items[i];

          if (set) {
            var $giftOption = $('<li class="" id="' + set.COLLECTION_ID + '">' + set.COLLECTION_NAME + '</li>');

            this.nodes.$dropdown.append($giftOption);
          }
        }
      }
    },

    /**
     * Method to set the click functionality after the options menu is created.
     * Scrapes the user drop down and adds a click ever to each option.
     * Click event updates the gift set id and name in the hidden form in drop down.
     */
    resetDropdownOptions: function () {
      var that = this;
      var $dropdownOptions = that.nodes.$dropdown.find('li');

      $dropdownOptions.each(function (i, option) {
        $(option).bind('click', function (e) {
          that.nodes.$chosenGiftsetID.val(this.id);
          that.nodes.$chosenGiftsetName.val(this.innerHTML);
          if ($(this).hasClass('js-create-new')) {
            that.nodes.$newGiftText.html('');
            that.nodes.$addGiftBtn.click();
          } else {
            $dropdownOptions.each(function (i, s) {
              $(s).removeClass('active');
            });
            $(this).addClass('active');
          }
        });
      });
    },

    /**
     * Method to handle getting the user.giftSets rpc call.
     * On success, the data is set to resetDropdown to populate the drop down.
     * If the data returned is empty, the gift drop down is hidden.
     */
    getCollections: function () {
      var that = this;

      generic.jsonrpc.fetch({
        method: 'user.giftSets',
        onSuccess: function (jsonRpcResponse) {
          var data = jsonRpcResponse.getValue();

          // Filter out null or empty values.
          data = _.without(data, null, '');

          that.resetDropdown(data);
          that.resetDropdownOptions();

          if (data.length >= 1) {
            that.showChooseMenu();
          } else {
            that.hideChooseMenu();
          }

          that.setScrolling();
          that.hideProgress();
        },
        onFailure: function (errorRpcResponse) {
          that.showErrors(errorRpcResponse);
        }
      });
    },

    /**
     * Method to handle creating a new gift collection for the user.
     * Once the add button is clicked, the add button is hidden while params are set and the rpc.form is called.
     * On success, the getCollections method is called again to repopulate the menu and displayed.
     */
    handler: function (context) {
      var that = context;

      that.showProgress();

      // add the item to the gift
      var collectionId = that.nodes.$chosenGiftsetID.val();
      var collectionName = that.nodes.$chosenGiftsetName.val();
      // In order to add the correct quantity of the product being gifted,
      // the RPC requires "SKU_BASE_ID" to be an array of repeated sku strings.
      // Example: "SKU BASE_ID": ['23456', '23456', '23456'] for quantity 3 of sku 23456
      var skuArray = [];

      for (var i = 0; i < that.itemQty; i++) {
        skuArray.push(that.giftSkuId);
      }

      if (!collectionName) {
        collectionName = that.nodes.$newGiftText.val();
      }

      // this call adds item to kit
      var params;

      if (that.multiAdd) {
        params = [
          {
            _SUBMIT: 'alter_collection',
            action: 'add,save',
            COLLECTION_TYPE: 'UKIT',
            COLLECTION_ID: collectionId,
            COLLECTION_NAME: collectionName || '',
            COLLECTION_SUBTYPE: 'SITEWIDE_GIFT',
            SKU_BASE_ID: giftSkuIds,
            INCREMENT: 1
          }
        ];
      } else {
        params = [
          {
            _SUBMIT: 'alter_collection',
            action: 'add,save',
            COLLECTION_TYPE: 'UKIT',
            COLLECTION_ID: collectionId,
            COLLECTION_NAME: collectionName || '',
            COLLECTION_SUBTYPE: 'SITEWIDE_GIFT',
            SKU_BASE_ID: skuArray
          }
        ];
      }

      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: params,
        onSuccess: function (jsonRpcResponse) {
          var resultData = jsonRpcResponse.getData();

          $(document).trigger('addToCart.success', [resultData]);
          that.getCollections();
          that.hideGiftMenu();
          that.hideInputError();
        },
        onFailure: function (errorRpcResponse) {
          that.showErrors(errorRpcResponse);
        }
      });
    },

    /**
     * Method to collect the DOM nodes for the module.
     * Each node is set with this.$NODE so throughout the class, you can refer to this.nodes.$NODE.
     * Any new DOM nodes should go here and not in the methods themselves.
     * Called right as the attach method is fired on intitalization.
     */
    nodes: {
      init: function ($btn) {
        this.$container = $btn.parent('.js-display-gifting');
        this.$quickshop = $btn.parents('.js-quickshop');
        this.$closeBtn = $('.js-gifting-close', this.$container);
        this.$addGiftNode = $('.js-add-to-giftbox', this.$container);
        this.$chooseGift = $('.js-choose-gift-set', this.$container);
        this.$dropdown = $('.js-gift-set-dropdown', this.$container);
        this.$giftForm = $('.js-gifting-form', this.$container);
        this.$addGiftBtn = $('.js-add-gift', this.$container);
        this.$newGiftText = $('.js-new-item-text', this.$container);
        this.$progressNode = $('.js-progress', this.$container);
        this.$chosenGiftsetID = $('.js-gift-set-selection-id', this.$container);
        this.$chosenGiftsetName = $('.js-gift-set-selection-name', this.$container);
        this.$errorMsgCont = $('.js-error-messages', this.$container);
        this.$qtyDropdown = $('.js-product-qty-select');
      }
    },

    /**
     * Method to set the page scroll when activating the gifting overlay.
     * Uses the window height and takes a percentage as the offset value.
     */
    setScrolling: function () {
      // Used to calculate scroll
      var gfTop = 0;
      var gfOffset = Math.ceil($(window).height() * 0.2);
      var speed = 400;

      gfTop = this.nodes.$giftForm.offset().top;

      // Scroll animation:
      $('html, body').animate(
        {
          scrollTop: gfTop - gfOffset
        },
        {
          duration: speed
        }
      );
    },

    /**
     * Method to handle the error display after a RPC call.
     * If error message is incorrect, use the RBE2 to set the error message text key returned.
     */
    showErrors: function (errorResponse) {
      var errorObjectsArray = errorResponse.getMessages();

      generic.showErrors(errorObjectsArray);
      this.hideProgress();
      this.showInputError();
    },

    showProgress: function () {
      this.nodes.$progressNode.removeClass('hidden');
      this.nodes.$addGiftBtn.addClass('hidden');
    },

    hideProgress: function () {
      this.nodes.$progressNode.addClass('hidden');
      this.nodes.$addGiftBtn.removeClass('hidden');
    },

    hideChooseMenu: function () {
      this.nodes.$chooseGift.addClass('hidden');
    },

    showChooseMenu: function () {
      this.nodes.$chooseGift.removeClass('hidden');
    },

    showInputError: function () {
      this.nodes.$newGiftText.addClass('error');
    },

    hideInputError: function () {
      this.nodes.$newGiftText.removeClass('error');
    },

    hideGiftMenu: function () {
      this.nodes.$addGiftNode.removeClass('active');
      this.nodes.$giftForm.addClass('hidden');
      this.nodes.$quickshop.removeClass('gifting-open');
    },

    showGiftMenu: function () {
      this.nodes.$addGiftNode.addClass('active');
      this.nodes.$giftForm.removeClass('hidden');
    },

    clearGiftNameInput: function () {
      this.nodes.$newGiftText.val('');
    },

    clearErrorMsg: function () {
      this.nodes.$errorMsgCont.empty();
    }
  };
})(jQuery);
